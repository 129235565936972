import './App.css';
import Footer from "./components/footer.js";
// import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from "./pages/home.js";
import NA from "./pages/NA";
import MyNFT from './pages/MyNft';

// import Web3Provider from './web3/wagmi';
import { Web3ProviderWrapper } from './web3/ethersWrapper.js';

function App() {
  return (
    <Web3ProviderWrapper>
      <Home />
    </Web3ProviderWrapper>
  )
}

export default App;
