import React, { useState, useContext, useEffect } from "react";
import {
    web3ProviderValue, setWeb3ProviderValue, Web3ProviderContext
} from "./ether.js"

export function Web3ProviderWrapper(props) {
    const context = useContext(Web3ProviderContext);
    const [currentAddress, setCurrentAddress] = useState(context.web3Address);
    const [contract, loadContract] = useState(context.web3Contract);
    const [provider, setProvider] = useState(context.web3Provider);

    function addressChange(currentAddress) {
        setCurrentAddress(currentAddress);
        console.log("Connected: " + currentAddress);
    }

    function newContract(contract) {
        loadContract(contract);
        // console.log("contract created");
    }

    function newProvider(provider) {
        setProvider(provider);
        // console.log("provider set");
    }

    return (
        <Web3ProviderContext.Provider
            value={{
                web3Address: currentAddress, addressChange: addressChange,
                web3Contract: contract, newContract: newContract,
                web3Provider: provider, newProvider: newProvider,
            }}
        >
            {props.children}
        </Web3ProviderContext.Provider>
    );
}