import '../styles/nfts.css'
import React from 'react';
import { Link } from "react-router-dom";
import { Web3ProviderContext } from '../web3/ether';
import { useState, useEffect, useContext } from 'react'
import { downBadBros_ADDRESS } from '../web3/config';
import ssc3 from '../images/example-3.png';
import ImageModal from '../components/modal.js';

// Modal.setAppElement("#root");

function NFTs() {
    const [nftList, setNFtList] = useState([]);
    const [alert, setAlert] = useState("");
    const [contract, contractUpdate] = useState(null);
    const [account, accountUpdate] = useState("");
    const context = useContext(Web3ProviderContext);

    const [show, setShow] = useState(false);
    const [imgSrc, setImgSrc] = useState("");

    const showModal = () => {
        setShow(true);
    }
    const hideModal = () => {
        setShow(false);
    }

    useEffect(() => {
        updateContext(context.web3Address, context.web3Contract);
    }, [context])

    const updateContext = (accountC, contractC, providerC) => {
        // console.log("context update");
        if (account !== accountC) {
            // console.log("account Update");
            accountUpdate(accountC);
        }
        if (contract !== contractC) {
            // console.log("contract Update");
            contractUpdate(contractC);
        }
    }

    const getOwnedNft = async () => {
        if (contract) {
            const balance = (await contract.balanceOf(account)).toNumber();
            console.log("NFT Bal: " + balance);
            var array = [];
            if (balance > 0) {
                setAlert("")
                // for (let i = 0; i < balance; i++) {

                try {
                    // var tokenId = await contract.tokenOfOwnerByIndex(account, i); //get token
                    // var uri = await contract.tokenURI(tokenId); //getImage
                    var response = await fetch(`https://api.paintswap.finance/v2/userNFTs?numToSkip=0&numToFetch=${balance}&user=${account}&orderBy=lastTransferTimestamp&orderDirection=desc&collections=${downBadBros_ADDRESS}`)
                        .catch(err => {
                            console.log(err);
                        });

                    var nftImage;

                    if (response) {
                        // console.log(response);
                        var nfts = await response.json();

                        for (let i = 0; i < balance; i++) {
                            var nft = nfts.nfts[i];
                            // console.log(nfts[i])
                            // console.log(nft.image)
                            const obj = { token: nft.tokenId, image: nft.image }
                            array.push(obj);
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            if (array.length) setNFtList(array);
            // }
            else {
                setAlert("You own 0 NFTs")
            }
        }
    }
    const testOwned = () => {
        var array = [];
        const balance = 8;
        for (let i = 0; i < balance; i++) {
            const obj = { token: i, image: ssc3 }
            array.push(obj);
        }
        setNFtList(array);
    }
    useEffect(() => {
        getOwnedNft();
        // testOwned();
    }, [account, contract])

    return (
        <>
            <div className="nft-section">
                <h1>Owned NFTs</h1>
                <h3>{alert}</h3>
                <div className="nft-container">
                    {nftList.map(({ token, image }) => (
                        <div className='nft' key={token}>
                            <a onClick={() => { setImgSrc(image); showModal(); }}><img src={image} alt="DownBad eh!" /></a>
                            <p>Token {token}</p>
                        </div>
                    ))}
                </div>
                <ImageModal
                    show={show}
                    onRequestClose={hideModal}
                >
                    <a><img src={imgSrc} alt="DownBad eh!" /></a>
                </ImageModal>
            </div>
        </>
    )
}

export default NFTs;