import '../styles/nav.css'
import { useState, useEffect, useContext } from "react"
import { ethers } from 'ethers'
import { downBadBros_ADDRESS, downBadBros_ABI } from './config.js';

export function Profile(props) {
    const [isConnected, setConnected] = useState(false);
    const [address, changeAddress] = useState("");
    const [currProvider, setCurrProvider] = useState(null);
    const [currSigner, setCurrSigner] = useState(null);
    const [alertError, setAlertError] = useState("");

    const connect = async (globalAddress, globalContract, globalProvider) => {
        const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
        if (provider) {
            console.log("Metamask installed");
        } else {
            console.log("Please install MetaMask!");
            return
        }
        setCurrProvider(provider);
        globalProvider(provider);

        const accounts = await provider.send('eth_requestAccounts', []);
        const account = accounts[0];

        if (account) {
            // console.log(account);
            setConnected(true);

            const signer = provider.getSigner();

            setCurrSigner(signer);
            changeAddress(account);
            globalAddress(account);

            const currChain = (await provider.getNetwork()).chainId;
            // console.log("Chain " + currChain);

            if (currChain !== 250) {
                setAlertError("* Switch to FTM");
            } else {
                createContract(provider, globalContract);
            }
            eventsListener(provider, globalAddress, globalContract);
        }
    }
    const createContract = async (provider, globalContract) => {
        if (provider) {
            try {
                const DownBadContract = new ethers.Contract(downBadBros_ADDRESS, downBadBros_ABI, provider.getSigner());
                globalContract(DownBadContract);
            } catch (err) {
                console.log(err);
            }
        }
    }
    async function checkChain(provider, chainId, globalContract) {
        // console.log(chainId);
        if (chainId !== '0xfa') {
            setAlertError("* Switch to FTM");
        } else {
            setAlertError("");
            createContract(provider, globalContract);
        }
    }
    async function checkAccount(provider, account, globalAddress) {
        // console.log(account);
        if (account != address) {
            // console.log("walletChanged");
            // console.log(address);
            changeAddress(account[0]);
            globalAddress(account[0]);
        }
    }
    async function eventsListener(provider, globalAddress, globalContract) {
        provider?.provider?.on('accountsChanged', (account) => {
            console.log("account Changed Event, " + account)
            checkAccount(provider, account, globalAddress);
        });
        provider?.provider?.on('chainChanged', (chainId) => {
            console.log("chain Changed Event, " + chainId)
            checkChain(provider, chainId, globalContract)
        });
    }

    // if (isConnected) {
    return (
        <div className="navbar">
            <div className="navbar-content">
                <div className="left-nav">
                    {/* <Link to="/"><img src={Logo} /></Link> */}
                </div>
                <div className="right-nav">
                    <div className="nav-links">
                        <div>
                            {isConnected ? <button onClick={() => { return; }}>{address.replace(address.substring(5, 38), "***")}</button>
                                : <button onClick={() => connect(props.addressChange, props.newContract, props.newProvider)}>CONNECT</button>}
                            <p className="alert-text">{alertError}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    // }

    // return (
    //     <div>
    //         {connectors.map((connector) => (
    //             <button
    //                 disabled={!connector.ready}
    //                 key={connector.id}
    //                 onClick={() => connect({ connector })}
    //             >
    //                 {connector.name}
    //                 {!connector.ready && ' (unsupported)'}
    //                 {isLoading &&
    //                     connector.id === pendingConnector?.id &&
    //                     '(connecting)'}
    //             </button>
    //         ))}
    //         {error && <div>{error.message}</div>}
    //     </div>
    // )
}