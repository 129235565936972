import '../styles/footer.css';
import React from 'react';
import discord from "../images/discord.png";
import twitter from "../images/twitter.png";
// import candle from "../images/candle.png";

function Footer() {
  return <div className="footer">
    <div className="social-links">
      <a target="_new" href="https://twitter.com/DOWNBADBROS_FTM"><img src={twitter} alt="twitter" /></a>
      <a target="_new" href="https://discord.gg/nD2BPZSvXD"><img src={discord} alt="discord" /></a>
    </div>

  </div>
}
export default Footer;