import '../styles/hero.css'
import React from 'react';
import thanks from "../images/thanks-anon.png";
import back from "../images/wagmi-text.png";
import Hero_IMG from "../images/mint-preview.png";
import logo from "../images/dbb-logo.png";
import rugged from "../images/text-descriptor.png";
import underline from "../images/underline-graphic.png";
import paintSwap from "../images/paintswap.png";
import nftKey from "../images/nftkey.png";

function Hero() {
    return (
        <>
            <div className="hero-container">
                <div className='hero-content'>
                    <div className='hero-image-container'>
                        <div className='hero-image'>
                            <img src={Hero_IMG} />
                            <div className='market'>
                                <h3>View in Market:</h3>
                                <a target="_new" href="https://paintswap.finance/marketplace/fantom/collections/0x4aa4c99e99847bbc5eb6b940cddfc902a5afecce/nfts" ><img src={paintSwap} /></a>
                                <a target="_new" href="https://nftkey.app/collections/downbadbros/"><img src={nftKey} /></a>
                            </div>

                        </div>
                        <div className='hero-text'>
                            <img src={logo} />
                            <img src={rugged} />
                            <img src={underline} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Hero;