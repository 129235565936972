import '../styles/modal.css'
import React from 'react';

function ImageModal(props) {
    const showHide = props.show ? "myoverlay display-block" : "myoverlay display-none"
    return (
        <>
            <div className={showHide}>
                <div className='mymodal'>
                    <button className="button-close" type="button" onClick={props.onRequestClose}>X</button>
                    {props.show ? props.children : "My modal"}
                </div>
            </div>
        </>
    )
}

export default ImageModal;