import '../styles/info.css'
import React from 'react';
import Services from "../components/services.js";
import textDesc from "../images/text-descriptor.png";

function Info() {
    return (
        <>
            <div className="page-container">

                <h1>LORE</h1>
                {/* <img src={textDesc} alt="Rugged, Scammed & Rekt"></img> */}
                <h2>To make it to the top, you must start from the very bottom...</h2>
                <p>A place where the moist air makes you wheeze and where the glow of the CRT monitor from 2001 is the only source of warmth. Chicken tendie crumbs sprinkle your bare, sweaty chest as you check price - bad price.</p>
                <h3>Once you have mastered this domain can you begin to think about making it all back?</h3>
                <h4>Will you WAGMI Anon?<br></br>Or will you remain DOWN BAD with these Bros?</h4>
            </div>
        </>
    )
}

export default Info;