import { ethers } from 'ethers'
import { useState, createContext, useContext } from "react";

export const web3ProviderValue = {
    web3Provider: null,
    web3Contract: null,
    web3Address: "",
}

export const Web3ProviderContext = createContext({
    web3Address: web3ProviderValue.web3Address,
    web3Contract: web3ProviderValue.web3Contract,
    addressChange: (address) => { },
    newContract: (contract) => { },
    web3Provider: web3ProviderValue.web3Provider,
    newProvider: (provider) => { },
});