import '../styles/header.css'
import '../styles/roadmap.css'
import React from 'react';
import bgImg from '../images/animation-slide.png'

function Roadmap() {
    return (
        <>
            <div className="roadmap-container">
                <div className='roadmap-text'>
                    <h1>ROADMAP</h1>
                    <div className='roadmap-item'>
                        <h1>10%</h1>
                        <h2>Get out of mom's basement</h2>
                    </div>
                    <div className='roadmap-item'>
                        <h1>50%</h1>
                        <h2>Be down but not as bad</h2>
                    </div>
                    <div className='roadmap-item'>
                        <h1>100%</h1>
                        <h2>Vibe</h2>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Roadmap