import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import '../styles/home.css';
import MyNFT from './MyNft';
import Header from "../components/header";
import { Profile } from "../web3/connect";
import { Web3ProviderContext } from '../web3/ether.js';
import Footer from "../components/footer";

function Home() {
    return (
        <Web3ProviderContext.Consumer>
            {({ addressChange, newContract, newProvider, newSigner }) => (
                <React.Fragment>
                    <Profile addressChange={addressChange} newContract={newContract} newProvider={newProvider} newSigner={newSigner} />
                    {/* <Header /> */}
                    <Router>
                        <Routes>
                            <Route exact path="/" element={<Header />} />
                            <Route path="/nft" element={<MyNFT />} />
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </Router>
                    <Footer />
                </React.Fragment>
            )}
        </Web3ProviderContext.Consumer>
    )
}

export default Home;