import '../styles/mint.css'
import Mint_IMG from "../images/mint.png";
import thanks from "../images/thanks-anon.png";
import back from "../images/wagmi-text.png";
import { React, ReactDOM } from 'react';
import { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { Web3ProviderContext, useWeb3Context } from '../web3/ether';
import axios from "axios";
import { ethers } from 'ethers';
import { CONK_ADD, GMFTM_ADD, CHILL_ADD, downBadBros_ADDRESS } from '../web3/config.js';


function Mint() {
  // Component state 
  const [currency, setCurrency] = useState("FTM")
  const [mintAmount, changeMintAmount] = useState(1);
  const [alertText, setAlertText] = useState("");
  const [alertError, setAlertError] = useState("");
  const [counter, setCounter] = useState("");
  // Contract read functions state
  const [mintStatus, setMintStatus] = useState(true);
  const [publicMint, setPublicMint] = useState(false);
  const [mintCost, setMintCost] = useState(50);
  const [totalCost, setTotalCost] = useState(50);
  const [mintSuccess, setMintSuccess] = useState(false);

  const [contract, contractUpdate] = useState(null);
  const [account, accountUpdate] = useState("");
  const [provider, providerUpdate] = useState(null);
  const context = useContext(Web3ProviderContext);

  useEffect(() => {
    updateContext(context.web3Address, context.web3Contract, context.web3Provider);
  }, [context])

  const updateContext = (accountC, contractC, providerC) => {
    // console.log("context update");
    if (account !== accountC) {
      // console.log("account Update");
      accountUpdate(accountC);
    }
    if (contract !== contractC) {
      // console.log("contract Update");
      contractUpdate(contractC);
    }
    if (provider !== providerC) {
      // console.log("provider Update");
      providerUpdate(providerC);
    }
  }
  useEffect(() => {
    // console.log(contract);
    contractRead();
  }, [contract])

  async function contractRead() {
    if (contract) {
      // console.log("contractRead");
      try {
        const status = await contract.mintPaused();
        console.log("Mint paused ", status);
        setMintStatus(status);
        if (status == true) {
          setAlertText("Mint is NOT LIVE !");
        }
        const mint = await contract.publicMint();
        console.log("Public mint ", mint);
        setPublicMint(mint);

        const cost = await contract.mintCost();
        console.log("Mint Cost FTM", cost / 1e18);
        setMintCost(cost / 1e18);

        const totalSupply = await contract.totalSupply();
        console.log("Total Supply " + totalSupply);
        setCounter(totalSupply.toString());
      } catch (error) {
        console.log(error);
      }
    }
  }

  const onCurrencyChange = (event) => {
    if (contract) {
      if (mintAmount < 0 || mintAmount > 5) {
        setAlertError("* Max per Txn is 5");
        return;
      } else {
        setAlertError("");
        setCurrency(event.target.value);
      }
    }
  }
  const onMintAmount = (event) => {
    if (contract) {
      if (event.target.value < 0 || event.target.value > 5) {
        setAlertError("* Max per Txn is 5")
        return;
      } else {
        setAlertError("");
        changeMintAmount(event.target.value);
      }
    }
  }
  useEffect(() => {
    showCost();
  }, [currency, mintAmount])

  const showCost = async () => {
    // console.log("Currency ", currency);
    // console.log("Mint amount ", mintAmount);
    const total = await calculatePrice();
    // console.log(total);
    setTotalCost(total);
  }

  const calculatePrice = async () => {
    try {
      if (currency == "FTM") {
        const totalPrice = mintAmount * mintCost;
        // console.log("price " + totalPrice + " " + currency);
        return totalPrice;
      } else {
        const tokenPerFTM = await contract.getTokenNeededPerFTM(currency);
        const hexNum = tokenPerFTM[1]._hex;
        // console.log(hexNum);
        const bigNumber = ethers.BigNumber.from(hexNum);
        // console.log(bigNumber);
        const totalPrice = mintAmount * mintCost * bigNumber / 1e18;
        return totalPrice;
      }
    } catch (error) {
      console.log("Error in calculate price\n" + error);
    }
  }

  const prepareMint = async () => {
    try {
      const getHexProof = await axios.post(".netlify/functions/getProof", { account });

      const arrayForContract = getHexProof.data["hexProof"];
      // console.log(getHexProof.data["hexProof"]);

      if (typeof (getHexProof.data.hexProof) == "undefined") {
        setAlertText('NOT whitelisted');
        console.log("NOT whitelisted");
        return [];
      } else {
        setAlertText('You are whitelisted');
        console.log("whitelisted");
        return arrayForContract;
      }
    } catch (error) {
      console.log("Error getting hex proff\n" + error);
    }
  }

  const tokenApprove = async (currency, value) => {
    let abiERC20 = [
      "function approve(address, uint256) external returns(bool)", "function balanceOf(address) external view returns(uint256)"
    ];
    var tokenAddress;
    switch (currency) {
      case "CONK":
        tokenAddress = CONK_ADD;
        break;
      case "GMFTM":
        tokenAddress = GMFTM_ADD;
        break;
      case "CHILL":
        tokenAddress = CHILL_ADD;
        break;
      default:
        tokenAddress = "";
    }
    let tokenContract = new ethers.Contract(tokenAddress, abiERC20, provider.getSigner());
    try {
      const approved = await tokenContract.approve(downBadBros_ADDRESS, value);
      return approved;
    } catch (error) {
      console.log("Error in Approve token\n" + error);
    }
  }

  const mintProcess = async (array, price, mintAmount) => {
    if (currency == "FTM") {
      // payableAMt, merkle, mintAmount
      console.log("Price: " + price);
      const sendAmt = ethers.utils.parseUnits(price.toString());
      const overrides = { from: account, value: sendAmt };
      setAlertText('Minting! Please wait');
      const transaction = await contract.mint(array, mintAmount, overrides);
      console.log(transaction);
      return transaction;
    } else {
      //tokenName, merkle, mintAmount
      console.log("Price: " + price);
      const sendAmt = ethers.utils.parseUnits(price.toString());
      try {
        const approveTrxn = await tokenApprove(currency, sendAmt);
        const rec = await approveTrxn.wait();
        if (rec) {
          setAlertText('Token Approve Success!');
          console.log("Approve success");
          // const overrides = { from: account, value: sendAmt };
          const transaction = await contract.mintWithToken(array, currency, mintAmount);
          console.log(transaction);
          return transaction;
        }
      } catch (error) {
        if (error.code === 4001) {
          setAlertError("* User denied Token Approve");
        } else {
          console.log("Error mint with token\n", error);
        }
      }
    }
  }
  const mintNFT = async () => {
    if (mintAmount < 0 || mintAmount > 5) {
      return;
    }
    if (contract) {
      console.log("Prepare Mint");

      if (publicMint == false) {
        const merkle = await prepareMint();
        console.log(merkle);

        if (merkle.length != 0) {
          const bigPrice = await calculatePrice();
          console.log("Mint big price " + bigPrice);
          // console.log(merkle);
          try {
            const trax = await mintProcess(merkle, bigPrice, mintAmount);
            const receipt = await trax.wait();
            if (receipt) {
              console.log("Success");
              setMintSuccess(true);
            }
          } catch (error) {
            if (error.code === 4001) {
              setAlertError("* User denied Transaction");
            } else {
              console.log(error);
            }
          }
        }
      } else {
        const bigPrice = await calculatePrice();
        console.log("Mint big price" + bigPrice);
        // console.log(merkle);
        try {
          const trax = await mintProcess([], bigPrice, mintAmount);
          const receipt = await trax.wait();
          if (receipt) {
            console.log("Success");
            setMintSuccess(true);
            const totalSupply = await contract.totalSupply();
            console.log("Total Supply " + totalSupply);
            setCounter(totalSupply.toString());
          }
        } catch (error) {
          if (error.code === 4001) {
            setAlertError("* User denied Transaction");
          } else {
            console.log(error);
          }
        }
      }
    } else {
      console.log("Contract not found");
    }
  }
  useEffect(() => {
    if (mintSuccess == true)
      setAlertText('Mint Success!\nThanks ANON!');
  }, [mintSuccess]);

  return (
    <Web3ProviderContext.Consumer>
      {({ currentAddress, contract, provider, signer }) => (
        <>
          <div className="mint-container">
            <div className='mint-section'>
              <div className='mint-text'>
                <h1>50 $FTM</h1>
                <h2>OR EQUIVALENT</h2>
                <h3>$CONK OR $GMFTM OR $CHILL</h3>
                <div className='mint-sub-text'>
                  <div className='sub-text'>
                    <h4>CHOOSE</h4>
                    <h5>YOUR</h5>
                    <h6>CURRENCY</h6>
                  </div>
                  <div className="button-group">
                    <div className="input-group">
                      <input className='input-radio' type="radio" value="FTM" id="ftm" name="Currency" checked={currency === "FTM"} onChange={onCurrencyChange} />
                      <span className='input-radio-custom'><label htmlFor="ftm">$FTM</label></span>
                    </div>
                    <div className="input-group">
                      <input className='input-radio' type="radio" value="CONK" id="conk" name="Currency" checked={currency === "CONK"} onChange={onCurrencyChange} />
                      <span className='input-radio-custom'><label htmlFor="conk">$CONK</label></span>
                    </div>
                    <div className="input-group">
                      <input className='input-radio' type="radio" value="GMFTM" id="gmftm" name="Currency" checked={currency === "GMFTM"} onChange={onCurrencyChange} />
                      <span className='input-radio-custom'><label htmlFor="gmftm">$GMFTM</label></span>
                    </div>
                    <div className="input-group">
                      <input className='input-radio' type="radio" value="CHILL" id="chill" name="Currency" checked={currency === "CHILL"} onChange={onCurrencyChange} />
                      <span className='input-radio-custom'><label htmlFor="chill">$CHILL</label></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mint-button'>
                <input type="number" placeholder={mintAmount} onChange={onMintAmount} min="1" max="5"></input>
                <p>Cost: {totalCost} ${currency}</p>
                <p className="alert-error">{alertError}</p>
                <p className="info">Minted: {counter}/999</p>
                <a onClick={mintNFT} className={mintStatus == true ? "disabled" : "mintImg"}> <img src={Mint_IMG} alt="mint" /></a>
                <p className="alert-text">{alertText}</p>
              </div>

            </div>
            <div className='view-nft'>
              {(currentAddress && contract) ?
                <Link className="nft-text" to="/nft">View your NFT(s) <FaEye style={{ paddingLeft: '10px', height: '28px' }} /></Link>
                : <Link className="nft-text" to="/nft">View your NFT(s) <FaEye style={{ paddingLeft: '10px', height: '28px' }} /></Link>}
            </div>
            <img className='back' src={back} />
          </div>
        </>
      )}
    </Web3ProviderContext.Consumer>
  )
}

export default Mint