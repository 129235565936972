import '../styles/header.css'
import Header_IMG from "../images/header.png";
import React from 'react';
import Mint from "../components/mint";
import Roadmap from "../components/roadmap";
import Info from "../components/info";
import { Link } from "react-router-dom";

function Header() {
    return (
        <React.Fragment>
            <div className="home-page">
                <div className="header-container">
                    <div className="header-content">
                        {/* <div className='vignette'> */}
                        <img src={Header_IMG} />
                        {/* </div> */}
                    </div>
                    <Mint />
                </div>
                <Info />
                <Roadmap />
                {/* <Row1 /> */}
                {/* <Row2 /> */}
            </div>
        </React.Fragment>
    )
}

export default Header