import React from "react";
import '../styles/nftpage.css';
import Hero from "../components/hero";
import NFTs from "../components/showNft";

function MyNFT() {
    return (
        <>
            <div className="nft-page">
                <Hero />
                <NFTs />
            </div>
        </>
    )
}
export default MyNFT;